import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.8rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
  text-align: center;
  padding-bottom: 4rem;

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 2.2rem;
    line-height: 3.5rem;
    padding-bottom: 8rem;
    height: 24rem;
  }
`;

const Footer = () => (
  <Wrapper>
    Buenbit es prestador del servicio operado por Oware SAS, sociedad constituida en Uruguay con el número 219260350013 y registrada bajo el Nº 39 como Proveedor de Servicios de Activos Virtuales (PSAV) ante la Comisión Nacional de Valores (CNV) de la República Argentina. Buenbit no hace intermediación financiera ni captación de ahorros.
  </Wrapper>
);

export default Footer;