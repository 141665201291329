import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (orientation: portrait) and (max-width: 767px) {
    width: 100%;
    margin-top: 8rem;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 6rem;
  line-height: 6rem;
  color: ${({ theme }) => theme.colors.secondary};
  margin: 0;

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 10rem;
    line-height: 10rem;
  }
`;

const ContrastTitle = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 8rem;
  line-height: 8rem;

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 15rem;
    line-height: 15rem;
  }
`;

const Subtitle = styled.h4`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  width: 70%;

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 4rem;
    line-height: 6rem;
    width: 100%;
  }
`;

const InfoBlock = () => (
  <Wrapper>
    <Title>
      Simulá tu
      <br />
      <ContrastTitle>crédito</ContrastTitle>
    </Title>
    <Subtitle>
        Ingresá la cantidad de nARS que querés recibir y analizá las variables de tu crédito.<br/><br/>
        El pago es ultra flexible. ¡Cómo y en cuántas veces quieras hacerlo!
    </Subtitle>
  </Wrapper>  
);

export default InfoBlock;
