import React from 'react';
import styled from 'styled-components';

import Divider from '../components/Divider';
import { DAI_YIELD, LTV, TNA } from '../data/constants';
import useCreditStore from '../state/useCreditStore';
import useTickersStore from '../state/useTickersStore';

const Wrapper = styled.div`
  width: calc(49% - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid ${({ theme }) => theme.colors.basic300};
  border-radius: 1.3rem;
  padding: 2rem;
  height: 100%;

  @media (orientation: portrait) and (max-width: 767px) {
    width: calc(100% - 8rem - 2px);
    margin-top: 8rem;
    padding: 4rem 4rem;
    border-radius: 3rem;
  }
`;

const InnerWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
`;

const PairLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2.5rem;

  @media (orientation: portrait) and (max-width: 767px) {
    width: 100%;
    margin-bottom: 7rem;
  }
`;

const PairText = styled.h4`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 700;
  margin: 0;

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 5rem;
    line-height: 5rem;
  }
`;

const SmallPairText = styled.h5<{ weight: number }>`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  line-height: 1.9rem;
  font-weight: ${({ weight }) => weight};
  margin: 0;

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 3.6rem;
    line-height: 3.6rem;
    min-width: 40%;
  }
`;

const DoubleLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const DisclaimerText = styled.p`
  margin: 0;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 400;
  width: 80%;

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 2.8rem;
    line-height: 4rem;
    margin-top: 1.5rem;
    width: 100%;
  }
`;

const InterestBlock = () => {
  const { days, amount } = useCreditStore();
  const tickers = useTickersStore((state) => state.tickers);

  const aprConvertor = (rate: number, days: number) => (
    (rate / 100 / 365 + 1) ** days
  );

  const interest = parseFloat(amount || '0') * aprConvertor(TNA, parseFloat(days || '0')) - parseFloat(amount || '0');
  const tea = aprConvertor(TNA, 365);

  const apr = (((1 + (DAI_YIELD / 100)) ** (1 / 365)) - 1) * 100 * 365;
  const apy = (aprConvertor(apr, parseFloat(days || '0')) - 1) * 100;

  const collateral = tickers?.dainuars > 0 ? (parseFloat(amount || '0') / (LTV / 100)) / tickers.dainuars : 0;

  return (
    <Wrapper>
      <PairLine style={{ marginBottom: 0 }}>
        <PairText>
            🧮​&nbsp;&nbsp;&nbsp;Intereses
        </PairText>
      </PairLine>
      <Divider />
      <InnerWrapper>
        <PairLine>
          <DoubleLineWrapper>
            <SmallPairText weight={500}>Tasa de interés anual (TNA)</SmallPairText>
            <DisclaimerText>
              La tasa es variable y los intereses pasan a formar parte del capital de tu crédito diariamente.
            </DisclaimerText>
          </DoubleLineWrapper>
          <SmallPairText weight={700} style={{ textAlign: 'right' }}>{TNA}%</SmallPairText>
        </PairLine>
        <PairLine>
          <DoubleLineWrapper>
            <SmallPairText weight={500}>Tasa Efectiva Anual (TEA)</SmallPairText>
            <DisclaimerText>
              La tasa efectiva acumulada durante un año, considerando que la TNA se compone diariamente.
            </DisclaimerText>
          </DoubleLineWrapper>
          <SmallPairText weight={700} style={{ textAlign: 'right' }}>{(tea * 100 - 100).toFixed(2)}%</SmallPairText>
        </PairLine>
        <PairLine>
          <DoubleLineWrapper>
            <SmallPairText weight={500}>Tasa Efectiva ({days || 0} días)</SmallPairText>
            <DisclaimerText>
              La tasa efectiva acumulada durante el período ingresado.
            </DisclaimerText>
          </DoubleLineWrapper>
          <SmallPairText weight={700} style={{ textAlign: 'right' }}>
            {(interest / parseFloat(amount || '0') * 100 || 0).toFixed(2)}%
          </SmallPairText>
        </PairLine>
        <PairLine>
          <SmallPairText weight={500}>Interés acumulado ({days || 0} días)</SmallPairText>
          <SmallPairText weight={700} style={{ textAlign: 'right' }}>{interest.toLocaleString(undefined, { maximumFractionDigits: 2 })} NARS</SmallPairText>
        </PairLine>
        <PairLine style={{ marginBottom: 0 }}>
          <SmallPairText weight={500} style={{ maxWidth: '60%' }}>Rendimiento de la garantía en DAI ({days || 0} días)</SmallPairText>
          <SmallPairText weight={700} style={{ textAlign: 'right' }}>{collateral > 0 ? (apy * collateral / 100).toLocaleString(undefined, { maximumFractionDigits: 2 }) : '-'} DAI</SmallPairText>
        </PairLine>
      </InnerWrapper>
    </Wrapper>
  );
};

export default InterestBlock;
