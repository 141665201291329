import create from 'zustand';

export enum ThemeModes {
  LIGHT = 'light',
  DARK = 'dark',
}

type ThemeState = {
  theme: ThemeModes;
  setTheme: (theme: ThemeModes) => void;
};

const useStore = create<ThemeState>((set) => ({
  theme: ThemeModes.DARK,
  setTheme: (theme) => {
    localStorage.setItem('theme', JSON.stringify(theme));
    set(() => ({ theme }));
  },
}));

export default useStore;
