import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 30%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 1.3rem;
  border: 1px solid ${({ theme }) => theme.colors.secondary};

  @media (orientation: portrait) and (max-width: 767px) {
    width: calc(100% - 3rem);
    margin-top: 3rem;
    padding: 2rem 1rem;
  }
`;

const Icon = styled.img`
  width: 20px;
  height: auto;
  margin: 0 8px 0 16px;
`;

const TextInput = styled.input`
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  outline: none;
  border: none;
  flex: 1;
  font-family: 'Inter', 'sans-serif';
  text-align: right;
  background-color: ${({ theme }) => theme.colors.background};

  ::placeholder {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 4rem;
    line-height: 5rem;
  }
`;

type InputProps = {
  label: string;
  value: string;
  handleChange: (value: string) => void;
  icon?: string;
}

const Input = ({ label, value, handleChange, icon }: InputProps) => {
  const onChange = (e: any) => {
    e.preventDefault();
    handleChange(e.target.value);
  };

  return (
    <Wrapper>
      {!!icon && <Icon src={icon} alt="Icono" />}
      <TextInput type="text" pattern="\d*" placeholder={label} value={value} onChange={onChange} />
    </Wrapper>
  )
};

export default Input;