import React from 'react';
import styled from 'styled-components';

import DAI from '../assets/dai.svg';
import NUARS from '../assets/nuARS.svg';
import Divider from '../components/Divider';
import Input from '../components/Input';
import { LTV, MAX_BORROW, MAX_DAYS } from '../data/constants';
import useCreditStore from '../state/useCreditStore';
import useTickersStore from '../state/useTickersStore';

const Wrapper = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid ${({ theme }) => theme.colors.basic300};
  border-radius: 1.3rem;
  padding: 2rem;
  margin-bottom: 1rem;

  @media (orientation: portrait) and (max-width: 767px) {
    width: calc(100% - 8rem - 2px);
    padding: 4rem 4rem;
    border-radius: 3rem;
    margin-top: 8rem;
  }
`;

const PairLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;

  @media (orientation: portrait) and (max-width: 767px) {
    width: 100%;
    margin-bottom: 7rem;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const HeaderLine = styled(PairLine)`
  flex-direction: row;
  align-items: center;
`;

const PairText = styled.h4`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 700;
  margin: 0;

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 5rem;
    line-height: 7rem;
  }
`;

const SmallPairText = styled.h5<{ weight: number }>`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: ${({ weight }) => weight};
  margin: 0;

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 4.2rem;
    line-height: 6rem;
    margin-top: 4rem;
  }
`;

const StyledSmallPairText = styled(SmallPairText)`
  @media (orientation: portrait) and (max-width: 767px) {
    margin-right: 1rem;
    width: 40%;
    text-align: right;
  }
`;

const SmallIcon = styled(SmallPairText)`
  margin-right: 1.8rem;

  @media (orientation: portrait) and (max-width: 767px) {
    margin-right: 3.5rem;
  }
`;

const JoinedWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 60%;

  @media (orientation: portrait) and (max-width: 767px) {
    align-self: flex-start;
    width: 100%;
    margin-bottom: 3rem;
  }
`;

const DoubleLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 60%;

  @media (orientation: portrait) and (max-width: 767px) {
    width: 100%;
  }
`;

const DisclaimerText = styled.p`
  margin: 0;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 400;

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 2.8rem;
    line-height: 4rem;
    margin-top: 1.5rem;
    width: 100%;
  }
`;

const PairImgsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const CurrencyIcon = styled.img`
  width: 3rem;
  height: 3rem;
  object-fit: cover;

  @media (orientation: portrait) and (max-width: 767px) {
    width: 8rem;
    height: 8rem;
  }
`;

const StyledCurrencyIcon = styled(CurrencyIcon)`
  margin-left: -1rem;
`;

const InputBlock = () => {
  const { amount, setAmount, days, setDays } = useCreditStore();
  const tickers = useTickersStore((state) => state.tickers);

  const handleAmountChange = (newAmount: string) => {
    if (parseFloat(newAmount) > MAX_BORROW && newAmount !== '') return;
    
    const finalAmount = newAmount.replace(/\D/g, '');

    setAmount(finalAmount);
  }

  const handleDaysChange = (newDays: string) => {
    if (parseFloat(newDays) > MAX_DAYS && newDays !== '') return;

    const finalDays = newDays.replace(/\D/g, '');

    setDays(finalDays);
  }

  return (
    <Wrapper>
      <HeaderLine style={{ marginBottom: 0 }}>
        <PairText style={{ alignSelf: 'center' }}>DAI/NARS</PairText>
        <PairImgsWrapper>
          <CurrencyIcon src={DAI} alt="DAI" />
          <StyledCurrencyIcon src={NUARS} alt="NARS" />
        </PairImgsWrapper>
      </HeaderLine>
      <Divider />
      <PairLine>
        <JoinedWrapper>
          <SmallIcon weight={500}>💰</SmallIcon>
          <SmallPairText weight={500}>Monto solicitado en nARS</SmallPairText>
        </JoinedWrapper>
        <Input label="" value={amount} handleChange={handleAmountChange} />
      </PairLine>
      <PairLine>
        <JoinedWrapper>
          <SmallIcon weight={500}>📅​</SmallIcon>
          <DoubleLineWrapper>
            <SmallPairText weight={500}>Cantidad de días</SmallPairText>
            <DisclaimerText>
              El pago es flexible: cómo y cuándo quieras, pero para calcular los intereses que tendrías que pagar, indicá un número de días estimados para cancelar tu crédito. 
            </DisclaimerText>
          </DoubleLineWrapper>
        </JoinedWrapper>
        <Input label="" value={days} handleChange={handleDaysChange} />
      </PairLine>
      <PairLine style={{ marginTop: '1rem', flexDirection: 'row' }}>
        <DoubleLineWrapper>
          <SmallPairText weight={500}>
            Garantía (DAI)
          </SmallPairText>
          <DisclaimerText>
            Es el monto de la garantía que debés dejar para recibir el monto del crédito que querés pedir. 
          </DisclaimerText>
        </DoubleLineWrapper>
        <StyledSmallPairText weight={700}>
          {tickers?.dainuars > 0
            ? ((parseFloat(amount || '0') / (LTV / 100)) / tickers.dainuars).toLocaleString(undefined, { maximumFractionDigits: 2 })
            : '-'
          } DAI
        </StyledSmallPairText>
      </PairLine>
    </Wrapper>
  )
};

export default InputBlock;
