import React, { useEffect } from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";

import useWindowProps from "./hooks/useWindowProps";

import TopBar from "./components/TopBar";

import "./App.css";
import Home from "./screens/Home";
import useTickersAPI from "./api/useTickersAPI";
import { ThemeModes } from "./state/useThemeStore";

declare module "styled-components" {
  export interface DefaultTheme {
    colors: { [index: string]: string };
  }
}

const GlobalStyle = createGlobalStyle`
  html {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: visible;
    font-size: min(1vw, 15px);
  }
  
  body {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 0;
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
  }
`;

const theme = {
  [ThemeModes.LIGHT]: {
    colors: {
      primary: "#00317A",
      secondary: "#1a9cff",
      background: "#fff",
      basic100: "#fff",
      basic300: "#E4E7F2",
      success: "#51C11D",
    },
  },
  [ThemeModes.DARK]: {
    colors: {
      primary: "#FFFFFF",
      secondary: "#FFABEA",
      background: "#141414",
      basic100: "#BDC1BB",
      basic300: "#3B3C3B",
      success: "#59C42D",
    },
  },
};

type WrapperProps = {
  height: number;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background};
  overflow-x: hidden;
`;

const ScreenWrapper = styled.div`
  width: 88rem;
`;

const App = (): JSX.Element => {
  const windowProps = useWindowProps();
  const { loadTickers } = useTickersAPI();

  // const { theme: themeMode, setTheme } = useThemeStore();

  useEffect(() => {
    loadTickers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
    // const storedTheme = localStorage.getItem("theme");
    // if (storedTheme) setTheme(JSON.parse(storedTheme) as ThemeModes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    document.getElementsByTagName('html')[0].style.backgroundColor = theme[ThemeModes.DARK].colors.background;
  }, []);

  return (
    <Router>
      <GlobalStyle />
      <ThemeProvider theme={theme[ThemeModes.DARK]}>
        <Wrapper height={windowProps.height}>
          <TopBar />
          <ScreenWrapper>
            <Home />
          </ScreenWrapper>
        </Wrapper>
      </ThemeProvider>
    </Router>
  );
};

export default App;
