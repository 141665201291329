import React from 'react';
import styled from 'styled-components';

import LogoImg from '../assets/logo.svg';
import ContrastLogoImg from '../assets/logoContrast.svg';
import useThemeStore, { ThemeModes } from '../state/useThemeStore';
// import ThemeToggle from './ThemeToggle';

const Wrapper = styled.div`
  width: 88rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0;

  @media (orientation: portrait) and (max-width: 1024px) {
    padding: 4rem 0;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    padding: 7rem 0;
  }
`;

const Logo = styled.img`
  width: 8rem;
  height: auto;

  @media (orientation: portrait) and (max-width: 1024px) {
    width: 12rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    width: 28rem;
  }
`;

const TopBar = () => {
  const theme = useThemeStore((state) => state.theme);

  return (
    <Wrapper>
      <Logo src={theme === ThemeModes.LIGHT ? LogoImg : ContrastLogoImg} alt="Buenbit" />
      {/* <ThemeToggle /> */}
    </Wrapper>
  );
};

export default TopBar;