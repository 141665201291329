import React from 'react';
import styled from 'styled-components';
import Footer from '../containers/Footer';
import HealthBlock from '../containers/HealthBlock';
import InfoBlock from '../containers/InfoBlock';
import InputBlock from '../containers/InputBlock';
import InterestBlock from '../containers/InterestBlock';
import PairBlock from '../containers/PairBlock';

const Wrapper = styled.div`
  height: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const BlockLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 4rem 0;
  height: 40rem;

  @media (orientation: portrait) and (max-width: 767px) {
    margin: 0;
    height: auto;
    flex-direction: column;
  }
`;

const Home = () => {
  return (
    <Wrapper>
      <BlockLine>
        <InfoBlock />
        <InputBlock />
      </BlockLine>
      <PairBlock />
      <BlockLine>
        <InterestBlock />
        <HealthBlock />
      </BlockLine>
      <Footer />
    </Wrapper>
  );
};

export default Home;
