import React from 'react';
import styled from 'styled-components';

import Divider from '../components/Divider';
import { DAI_YIELD, LTV, THRESHOLD, TNA } from '../data/constants';
import useTickersStore from '../state/useTickersStore';

const Wrapper = styled.div`
  width: calc(100% - 4rem - 2px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid ${({ theme }) => theme.colors.basic300};
  border-radius: 1.3rem;
  padding: 2rem;
  margin-bottom: 3rem;

  @media (orientation: portrait) and (max-width: 767px) {
    width: calc(100% - 8rem - 2px);
    margin-top: 5rem;
    padding: 4rem 4rem;
    border-radius: 3rem;
  }
`;

const PairLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2.5rem;

  @media (orientation: portrait) and (max-width: 767px) {
    width: 100%;
    margin-bottom: 7rem;
  }
`;

const PairText = styled.h4`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 700;
  margin: 0;

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 5rem;
    line-height: 5rem;
  }
`;

const SmallPairText = styled.h5<{ weight: number }>`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: ${({ weight }) => weight};
  margin: 0;

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 3.6rem;
    line-height: 3.6rem;
  }
`;

const DoubleLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const DisclaimerText = styled.p`
  margin: 0;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 400;
  width: 80%;

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 2.8rem;
    line-height: 4rem;
    margin-top: 1.5rem;
  }
`;

const PairBlock = () => {
  const tickers = useTickersStore((state) => state.tickers);

  return (
    <Wrapper>
      <PairLine style={{ marginBottom: 0 }}>
        <PairText>🤓​&nbsp;&nbsp;&nbsp;Información general</PairText>
      </PairLine>
      <Divider />
      <PairLine>
        <SmallPairText weight={500}>Cotización DAI/NARS</SmallPairText>
        <SmallPairText weight={700}>{tickers?.dainuars > 0 ? `${tickers.dainuars.toLocaleString(undefined, { maximumFractionDigits: 2 })} NARS` : '-'}</SmallPairText>
      </PairLine>
      <PairLine>
        <SmallPairText weight={500}>Rendimiento anual DAI (TEA)</SmallPairText>
        <SmallPairText weight={700}>{DAI_YIELD}%</SmallPairText>
      </PairLine>
      <PairLine>
        <DoubleLineWrapper>
          <SmallPairText weight={500}>Tasa de interés anual (TNA)</SmallPairText>
          <DisclaimerText>
            La tasa es variable y los intereses pasan a formar parte del capital de tu crédito diariamente.
          </DisclaimerText>
        </DoubleLineWrapper>
        <SmallPairText weight={700}>{TNA}%</SmallPairText>
      </PairLine>
      <PairLine>
        <DoubleLineWrapper>
          <SmallPairText weight={500}>Relación crédito/garantía (LTV)</SmallPairText>
          <DisclaimerText>
            La relación inicial entre tu crédito y tu garantía. Mientras más pidas, más DAI deberás dejar como garantía.
          </DisclaimerText>
        </DoubleLineWrapper>
        <SmallPairText weight={700}>{LTV}%</SmallPairText>
      </PairLine>
      <PairLine style={{ marginBottom: 0 }}>
        <DoubleLineWrapper>
          <SmallPairText weight={500}>Umbral de liquidación</SmallPairText>
          <DisclaimerText>
            Si la relación entre crédito-garantía (LTV) aumenta hasta este porcentaje, liquidaremos tu garantía para cubrir tu deuda.
          </DisclaimerText>
        </DoubleLineWrapper>
        <SmallPairText weight={700}>{THRESHOLD}%</SmallPairText>
      </PairLine>
    </Wrapper>
  );
};

export default PairBlock;
