import create from 'zustand';

type CreditState = {
  amount: string;
  setAmount: (amount: string) => void;
  days: string;
  setDays: (days: string) => void;
};

const useStore = create<CreditState>((set) => ({
  amount: '100000',
  setAmount: (amount) => set(() => ({ amount })),
  days: '30',
  setDays: (days) => set(() => ({ days })),
}));

export default useStore;
