import React from 'react';
import styled from 'styled-components';

import Divider from '../components/Divider';
import { LTV, THRESHOLD } from '../data/constants';
import useTickersStore from '../state/useTickersStore';

const Wrapper = styled.div`
  width: calc(49% - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  border: 1px solid ${({ theme }) => theme.colors.basic300};
  border-radius: 1.3rem;
  padding: 2rem;
  height: 100%;

  @media (orientation: portrait) and (max-width: 767px) {
    width: calc(100% - 8rem - 2px);
    margin-top: 8rem;
    padding: 4rem 4rem;
    border-radius: 3rem;
  }
`;

const PairLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2.5rem;

  @media (orientation: portrait) and (max-width: 767px) {
    width: 100%;
    margin-bottom: 7rem;
  }
`;

const PairText = styled.h4`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 700;
  margin: 0;

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 5rem;
    line-height: 5rem;
  }
`;

const SmallPairText = styled.h5<{ weight: number }>`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: ${({ weight }) => weight};
  margin: 0;

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 3.6rem;
    line-height: 3.6rem;
  }
`;

const DoubleLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 70%;

  @media (orientation: portrait) and (max-width: 767px) {
    width: 60%;
  }
`;

const DisclaimerText = styled.p`
  margin: 0;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 400;

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 2.8rem;
    line-height: 4rem;
    margin-top: 1.5rem;
  }
`;

const HealthFactor = styled(SmallPairText)`
  background-color: ${({ theme }) => theme.colors.success};
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;

  @media (orientation: portrait) and (max-width: 767px) {
    padding: 1rem 3rem;
    border-radius: 3rem;
  }
`;

const HealthBlock = () => {
  const tickers = useTickersStore((state) => state.tickers);

  return (
    <Wrapper>
      <PairLine style={{ marginBottom: 0 }}>
        <PairText>
          🩺​&nbsp;&nbsp;&nbsp;Salud del crédito
        </PairText>
      </PairLine>
      <Divider />
      <PairLine>
        <DoubleLineWrapper>
          <SmallPairText weight={500}>Relación crédito/garantía (LTV)</SmallPairText>
          <DisclaimerText>La relación inicial entre tu crédito y tu garantía. Mientras más pidas, más DAI deberás dejar como garantía.</DisclaimerText>
        </DoubleLineWrapper>
        <SmallPairText weight={700}>{LTV}%</SmallPairText>
      </PairLine>
      <PairLine>
        <DoubleLineWrapper>
          <SmallPairText weight={500}>Umbral de liquidación</SmallPairText>
          <DisclaimerText>
            Si la relación entre crédito-garantía (LTV) aumenta hasta este porcentaje, liquidaremos tu garantía para cubrir tu deuda.
          </DisclaimerText>
        </DoubleLineWrapper>
        <SmallPairText weight={700}>{THRESHOLD}%</SmallPairText>
      </PairLine>
      <PairLine>
        <DoubleLineWrapper>
          <SmallPairText weight={500}>Índice de salud</SmallPairText>
          <DisclaimerText>
            Permite monitorear si tu garantía está cercana a liquidarse. Al solicitar este crédito,{' '}
            tu índice de salud comenzará en 1,12. Para evitar que se liquide tu garantía, tendrás que{' '}
            mantenerlo por encima de 1,0.
          </DisclaimerText>
        </DoubleLineWrapper>
        <HealthFactor weight={700}>{(THRESHOLD / LTV).toFixed(2)}</HealthFactor>
      </PairLine>
      <PairLine style={{ marginBottom: 0 }}>
        <DoubleLineWrapper>
          <SmallPairText weight={500}>Cotización de liquidación</SmallPairText>
          <DisclaimerText>
            La cotización de tu garantía de DAI en nARS es variable. Si la cotización disminuye del{' '}
            valor mostrado y no cubre tu crédito, liquidaremos tu garantía.
          </DisclaimerText>
        </DoubleLineWrapper>
        <SmallPairText weight={700}>
          {tickers?.dainuars > 0 ? (tickers.dainuars / (THRESHOLD / LTV)).toLocaleString(undefined, { maximumFractionDigits: 2 }) : '-'} NARS
        </SmallPairText>
      </PairLine>
    </Wrapper>
  );
};

export default HealthBlock;
