import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.basic300};
  margin: 2.5rem 0;

  @media (orientation: portrait) and (max-width: 767px) {
    margin: 5rem 0;
  }
`;

const Divider = () => <Wrapper />;

export default Divider;
