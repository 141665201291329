import create from 'zustand';

type Tickers = { [index: string]: number };

type TickersState = {
  tickers: Tickers;
  set: (tickers: Tickers) => void;
};

const useStore = create<TickersState>((set) => ({
  tickers: {},
  set: (tickers) => set(() => ({ tickers })),
}));

export default useStore;
