/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

import useTickersStore from '../state/useTickersStore';
// import tickersMockResponse from './tickersMockResponse';

const BASE_URL = 'https://be.buenbit.com/api';

type TickersAPI = {
  loadTickers: () => void;
};

const useTickersAPI = (): TickersAPI => {
  const { set } = useTickersStore();

  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 30000,
  });

  const loadTickers = async () => {
    const {
      data: { object },
    } = await axiosInstance.get('/market/tickers/', {
      headers: { 'X-BB-Country': 'AR' },
    });

    // const {
    //   data: { object },
    // } = tickersMockResponse;

    const tickers = {
      dainuars: parseFloat(object.dainuars.purchase_price),
    };

    set(tickers);
  };

  return {
    loadTickers,
  };
};

export default useTickersAPI;
